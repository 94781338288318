import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Link } from "gatsby"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./mission.module.css"
import catImage from "../assets/cat1.svg"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import MetaTags from "../components/MetaTags"

// markup
const Misi = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [canvasVisibility, setCanvasVisibility] = React.useState("none")

  return (
    <>
      <MetaTags
        metaDescription="Misi"
        metaTitle="Misi"
        metaPath="/mission"
        metaPreviewImage={metaPreviewImage}
      />
      <Header />

      <Container className={css.section}>
        <Row>
          <Col>
            <h1>Misi</h1>
            <div className={css.mission}>
              <h2>"Menemukan Lebih Banyak Hewan Yang Hilang"</h2>
            </div>

            <div className={css.titleFigure}>
              <img src="https://bucket-prod.ktpanabul.com/assets/cat_video1_thumb.webp" className={css.cat2} />
              <video
                className={css.cat1}
                autoPlay={true}
                loop={true}
                poster="https://bucket-prod.ktpanabul.com/assets/cat_video1_thumb.webp"
              >
                <source
                  src="https://bucket-prod.ktpanabul.com/assets/cat_video1.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <p>
              Halo para pecinta binatang!
              <br />
              <br />
              Aku <b>Khakim Hudaya</b> (<a href="https://x.com/huedaya" target="_blank">@huedaya <FontAwesomeIcon icon={faTwitter} /></a>) ingin mencoba memulai misi
              sederhana, yaitu membantu menemukan Anabul (baca: anak bulu/hewan peliharaan) yang hilang. Bagaimana
              caranya?
              <br />
              <br />
              <u>Pertama</u> aku mencoba mengumpulkan sebanyak mungkin informasi &amp; ciri anabul yang hilang
              dalam website yang terpusat, yaitu website ini. Setiap anabul yang di-input akan dibuatkan <a href="/@bonbon" target="_blank">halaman khusus </a>
              yang informasinya dapat dengan mudah diperbaharui oleh pemilik anabul tersebut. Informasi
              tersebut termasuk lokasi dimana anabul tersebut tinggal dan pemiliknya.
              <br />
              <br />
              <u>Kedua</u>, data tersebut kemudian akan dipetakan dan memungkinkan siapa saja untuk mengetahui <Link to='/missing-pets'>anabul
                yang hilang</Link> disekitar dia berada, sistem juga akan mengunggah posting otomatis di Twitter <a href="https://twitter.com/KtpAnabul" target="_blank">@KtpAnabul <FontAwesomeIcon icon={faTwitter} /></a>. Penemu anabul hilang kemudian bisa melaporkan atau menghubungi pemilik
              melalui kontak dan informasi yang tertera dalam halaman profil khusus tadi.
              <br />
              <br />
              <u>Selanjutnya</u>, aku juga akan membuat berbagai aplikasi untuk mempermudah proses pencarian
              anabul, seperti Aplikasi Pembuat Fleyer otomatis dan database kode chip. <br />
              <br />
              Namun, untuk mencapai misi tersebut aku perlu bantuan dari teman-teman untuk membagikan aplikasi ini,
              dan aku ingin website ini dibuat berdasarkan masukan dari teman-teman
              semua. Jadi, jika ada saran dan masukan, jangan ragu untuk menghubungi aku di Twitter ya!
              <br />
              <br />
              Semoga proyek ini bisa membawa lebih banyak kebahagiaan! Yuk kita mulai! 😃

              <br />
              <br />
              <Link to={'/features'} className={css.noUnderline}><Button size={'lg'} variant="outline-primary">Lihat Fitur <FontAwesomeIcon icon={faArrowRight} /></Button></Link>

            </p>

            {/* <p>
              Goal lain: Aku pengen coba mencari data yang unik, misal berapa banyak sebaran 
              data kucing jenis ini di daerah A, atau berapa banyak kucing bermata biru di daerah B. dll
              Data ini nantinya dibuat publik. 

              Data ini menurutku berguna untuk penelitian dll
            </p> */}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Misi
